import type { FC } from 'react';

const IntroductionBanner: FC = () => {
  return (
    <div className="flex-grow relative overflow-hidden text-white bg-infoBlue !p-[30px] flex justify-center items-center">
      <div className="absolute top-1/2 right-8 -translate-y-1/2">
        <img src="/DTH_Logo_water_mark.png" alt="DTH logo" className="h-[569px] w-[786px]" />
      </div>
      <div className="space-y-[125px]">
        <div className="space-y-4">
          <h3 className="text-[40px] leading-[50px] text-justify font-bold font-jnjDBold">Welcome to DTH!</h3>
          <p className="text-base/4 font-normal font-jnjTRegular">
            The objective of the Digital Transformation Hub (DTH) initiative is to create a user friendly online
            platform specifically designed for Research and Development (R&D) Quality management. This portal aims to
            provide a centralized and accessible solution for R&D professionals, teams and organizations.
          </p>
        </div>
        <p>Please select a tool to proceed.</p>
      </div>
    </div>
  );
};

export default IntroductionBanner;

import { FC, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../hooks';
import { applicationsActions } from '../redux/slices/applicationSlice';

import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';

import ApplicationList from './ApplicationList';
import Scroll from '../atoms/Scroll';

const Applications: FC = () => {
  const dispatch = useAppDispatch();
  const allApps = useAppSelector(state => state.apps.filteredApps);
  const recents = useAppSelector(state => state.apps.filteredRecents);

  useEffect(() => {
    dispatch(applicationsActions.fetchApplications());
  }, []);

  const showRecents = !!recents.length;

  return (
    <div className="pl-6 pr-[38px] pt-[94px] flex flex-col flex-grow space-y-4">
      <div className="w-1/2">
        <FormControl variant="outlined" className="w-full">
          <OutlinedInput
            placeholder="Search for Applications"
            className="rounded-lg"
            inputProps={{
              className: 'p-2 text-base/6 font-normal font-jnjTRegular',
            }}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            }
            onChange={event => dispatch(applicationsActions.updateSearchQuery(event.target.value))}
          />
        </FormControl>
      </div>
      <Scroll className="flex flex-col flex-grow">
        <div className="overflow-auto flex-grow space-y-14 h-0 pb-[100px]">
          {showRecents && <ApplicationList title="Recent Apps" items={recents} disablePopup />}
          <ApplicationList
            title="All Apps"
            items={allApps}
            cardProps={{ imageOverlayClass: 'p-6', imageClass: 'h-[64px] w-[69px]' }}
          />
        </div>
      </Scroll>
    </div>
  );
};

export default Applications;

import { Box, Typography } from '@mui/material';
import React from 'react';
import { ReactComponent as JNJLogo } from '../assets/images/JNJ_Logo.svg';
import { RedTheme } from '../global/theme';
import { SelectedTheme } from '../global/selectedTheme';
/**
* Footer.
* Logo
* FQA
* Contact Us
* Use JNJLogo
* Use JNJ Logo Component
* Use Box component
* Typo component
* Link Component
* All Rights Reserved
* @returns {JSX.Element}
*/
export const Footer = () => {
  let redtheme: any =RedTheme;
  const theme :any = SelectedTheme();
 
  return (
    <>
      <Box
        component='footer'
        sx={{
          padding: '0px 72px',
          width: '100%',
          height: '100px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
          gap: '56px',
          background: '#FFFFFF',
          boxShadow: '0px 5px 6px rgba(0, 0, 0, 0.12)',
          borderTop: '1px solid rgba(33, 33, 33, 0.1)',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            width: '60%',
          }}
        >
          <Box
            sx={{
              marginRight: '50px',
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <JNJLogo fill={`${theme.palette.primary.main}`} height='24' width='257.91' />
          </Box>
          <Box
            sx={{
              display: 'flex',
              marginRight: '50px',
              height: '44px',
            }}
          >
          </Box>
        </Box>
        <Box
          sx={{
            flexGrow: 0,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            width: '40%',
          }}
        >
          <Typography variant='caption' sx={{
            fontSize: '10px',
            lineHeight: '12px',
            fontWeight: 500,
            color: redtheme.palette.fontColor.dark_grayish_blue,
            fontFamily: '"JohnsonText-Light"'
          }}>
            All Rights Reserved
          </Typography>
        </Box>
      </Box>
    </>
  );
};

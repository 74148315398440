import { UserDetail } from "../components/userDetail";

const alloc8orRoleToEnvMap = {
    dev: 'ITS-DTH-ALLOC8ORADMIN-DEV',
    qa: 'ITS-DTH-ALLOC8ORADMIN-QA',
    production: 'ITS-DTH-ALLOC8ORADMIN'
}

export const checkForAlloc8orAccess = () => {
    const environment = process.env.REACT_APP_ENVIRONMENT?.toLowerCase();
    const roles = UserDetail().userRoles || [];
    return roles.includes(alloc8orRoleToEnvMap[environment as keyof typeof alloc8orRoleToEnvMap])
}

export const createAppUrl = (url: string) => {
  const env = process.env.REACT_APP_ENVIRONMENT?.toLowerCase() || '';
  if (env === 'production') return url.replace('[env]', '');
  return url.replace('[env]', env?.concat('.'));
};
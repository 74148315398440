import { jwtDecode } from "jwt-decode";
import axios, { HttpStatusCode } from "axios";

export function getAuthToken() {
  let keys = Object.keys(localStorage);
  let token = "",
    graphToken = "",
    userEmailId = "",
    userName = "",
    roles = [],
    userFirstName = "",
    userLastName = "";

  for (let index = 0; index < keys.length; index++) {
    const key = keys[index];

    let val = localStorage.getItem(key);
    if (val && validJSON(val)) {
      let parsedVal = JSON.parse(val);
      if (Object.prototype.hasOwnProperty.call(parsedVal, "credentialType")) {
        if (parsedVal.credentialType === "IdToken") {
          token = parsedVal.secret;
        } else if (parsedVal.credentialType === "AccessToken") {
          graphToken = parsedVal.secret;
        }
      } else if (
        Object.prototype.hasOwnProperty.call(parsedVal, "authorityType")
      ) {
        userEmailId = parsedVal.username;
        userName = parsedVal.name;
        roles = parsedVal?.idTokenClaims?.roles;
      }
    }
  }
  return {
    idToken: token,
    graphToken: graphToken,
    userEmailId: userEmailId,
    userName: userName,
    roles: roles,
    userFirstName: userFirstName,
    userLastName: userLastName,
  };
}

function validJSON(str) {
  try {
    // eslint-disable-next-line no-unused-vars
    let obj = JSON.parse(str);
    if (obj) {
      return true;
    }
  } catch (error) {
    return false;
  }
  return true;
}

export const getDecodedBearerToken = () => {
  let token = getAuthToken();
  const tokenData = `Bearer ${token.idToken}`;
  return jwtDecode(tokenData);
};

export const saveLocalStorageDetails = async () => {
  let localStorageContents = [];
  const keys = Object.keys(localStorage);
  keys.forEach((key) => {
    const val = localStorage.getItem(key);
    if (val && validJSON(val)) {
      localStorageContents.push({
        [key]: val,
      });
    }
  });

  const token = getAuthToken();
  axios.defaults.headers.common["Authorization"] = `Bearer ${token?.idToken}`;
  axios.defaults.headers.common["Content-Type"] = "application/json";
  try {
    await axios.post(`${process.env.REACT_APP_MSAL_ENDPOINT}`, {
      val: localStorageContents,
    });
  } catch (error) {
    if (
      error?.response?.status === HttpStatusCode.InternalServerError ||
      error?.response?.status === HttpStatusCode.Forbidden
    ) {
      // Session expired
      localStorage.clear();
      window.location.replace(`${process.env.REACT_APP_REDIRECT_URI}`);
    }
  }
};

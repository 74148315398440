import { configureStore, ThunkAction, Action, createListenerMiddleware } from '@reduxjs/toolkit';
import applicationsReducer, { applicationsActions } from './slices/applicationSlice';

const listenerMiddleware = createListenerMiddleware();

export const store = configureStore({
  reducer: {
    apps: applicationsReducer,
  },
  middleware: getDefaultMiddlewares => getDefaultMiddlewares().prepend(listenerMiddleware.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

listenerMiddleware.startListening({
  actionCreator: applicationsActions.handleRecents,
  effect: (_, { dispatch }) => {
    (dispatch as AppDispatch)(applicationsActions.postRecents());
    (dispatch as AppDispatch)(applicationsActions.filterApplications());
  },
});

listenerMiddleware.startListening({
  actionCreator: applicationsActions.updateSearchQuery,
  effect: (_, { dispatch }) => {
    (dispatch as AppDispatch)(applicationsActions.filterApplications());
  },
});

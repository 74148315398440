import { Routes, Route, BrowserRouter } from "react-router-dom";
import { ParentRootLayout } from "../pages/parentRootLayout";
const RouterOutlet = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<ParentRootLayout />}/>
        <Route index element={<ParentRootLayout />}/>
      </Routes>
    </BrowserRouter>
  );
};

export default RouterOutlet;
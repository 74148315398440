// Saving all the project level constants here
export const LINK_TO_TAP = process.env.REACT_APP_REDIRECT_URI_TAP;
export const LINK_TO_STEP = process.env.REACT_APP_REDIRECT_URI_STEP;
export const LINK_TO_ALLOC8OR = process.env.REACT_APP_REDIRECT_URI_ALLOC8OR;
export const DTH_AUTH_DETAILS = 'DTH_Auth_Details';
export const numbers = {
  ONE: 1,
  TEN: 10,
  SIXTY: 60,
  THOUSAND: 1000,
  FOURHUNDREDONE: 401,
};

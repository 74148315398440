import { CSSProperties, FC, Fragment, useRef, useState } from 'react';
import { Application, applicationsActions } from '../redux/slices/applicationSlice';
import type { SxProps, PopoverOrigin } from '@mui/material';
import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';
import { createAppUrl } from '../utils/helpers';
import { useAppDispatch } from '../hooks';

export interface AppPopupProps {
  paperStyles?: SxProps;
  appContainerStyles?: SxProps;
  logoContainerStyles?: SxProps;
  logoStyles?: CSSProperties;
  appLabelStyles?: SxProps;
  descriptionStyles?: SxProps;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
}

export interface ApplicationCardProps {
  app: Application;
  disablePopup?: boolean;
  containerClass?: string;
  imageOverlayClass?: string;
  imageClass?: string;
  labelClass?: string;
  popupProps?: AppPopupProps;
}

const ApplicationCard: FC<ApplicationCardProps> = ({
  app,
  disablePopup = true,
  containerClass = '',
  imageOverlayClass = '',
  imageClass = '',
  labelClass = '',
  popupProps = {},
}) => {
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const ref = useRef<HTMLDivElement | null>(null);

  const handleClose = () => setAnchorEl(null);

  const openApp = () => {
    dispatch(applicationsActions.handleRecents(app));
    window.open(createAppUrl(app.url), '_blank');
  };

  const isPopupOpen = Boolean(anchorEl);

  return (
    <Fragment>
      <div
        className={`space-y-2 cursor-pointer flex flex-col items-center ${containerClass}`}
        ref={ref}
        onClick={() => disablePopup && openApp()}
        onMouseOver={() => !disablePopup && setAnchorEl(ref.current)}
      >
        <div
          className={`-:px-6 -:py-7 -:rounded-2xl -:bg-white -:shadow-appCard -:flex -:justify-center -:items-center -:w-fit ${imageOverlayClass}`}
        >
          <img src={app.imagePath} alt={app.label} className={`-:h-[88px] -:w-[95px] ${imageClass}`} />
        </div>
        <p className={`-:text-base/4 -:font-jnjTRegular -:font-normal -:text-center -:text-black ${labelClass}`}>
          {app.label}
        </p>
      </div>
      <Popover
        open={isPopupOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        onMouseLeave={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left', ...(popupProps.anchorOrigin || {}) }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'left', ...(popupProps.transformOrigin || {}) }}
        slotProps={{
          paper: {
            onClick: openApp,
            onMouseLeave: handleClose,
            sx: {
              maxWidth: '272px',
              padding: '20px',
              backgroundColor: '#004685',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              gap: '8px',
              borderRadius: '16px',
              cursor: 'pointer',
              ...(popupProps.paperStyles || {}),
            },
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            ...(popupProps.appContainerStyles || {}),
          }}
        >
          <Box
            sx={{
              padding: '24px',
              backgroundColor: 'white',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '16px',
              marginBottom: '8px',
              ...(popupProps.logoContainerStyles || {}),
            }}
          >
            <img
              src={app.imagePath}
              alt={app.label}
              style={{ height: '69px', width: '75px', ...(popupProps.logoStyles || {}) }}
            />
          </Box>
          <Box
            component="p"
            sx={{
              fontSize: '16px',
              lineHeight: '16px',
              fontWeight: 500,
              fontFamily: 'JohnsonText-Regular, sans-serif',
              textAlign: 'center',
              color: 'white',
              ...(popupProps.appLabelStyles || {}),
            }}
          >
            {app.label}
          </Box>
        </Box>
        <Box
          component="p"
          sx={{
            fontSize: '14px',
            lineHeight: '16px',
            fontFamily: 'JohnsonText-Regular, sans-serif',
            textAlign: 'center',
            color: 'white',
            fontWeight: 500,
            ...(popupProps.descriptionStyles || {}),
          }}
        >
          {app.description}
        </Box>
      </Popover>
    </Fragment>
  );
};

export default ApplicationCard;

import React, { FC } from 'react';
import { Application } from '../redux/slices/applicationSlice';
import ApplicationCard, { ApplicationCardProps } from './ApplicationCard';

interface ApplicationListProps {
  title?: string;
  items: Application[];
  cardProps?: Omit<ApplicationCardProps, 'app' | 'disablePopup'>;
  containerClass?: string;
  titleClass?: string;
  itemsContainerClass?: string;
  disablePopup?: boolean;
}

const ApplicationList: FC<ApplicationListProps> = ({
  title,
  items = [],
  cardProps = {},
  containerClass = '',
  titleClass = '',
  itemsContainerClass = '',
  disablePopup = false,
}) => {
  return (
    <div className={`-:space-y-6 ${containerClass}`}>
      {title && <h3 className={`-:text-2xl/6 -:font-medium -:font-jnjDBold ${titleClass}`}>{title}</h3>}
      <div className={`-:grid -:grid-cols-5 -:grid-rows-[auto] -:gap-10 ${itemsContainerClass}`}>
        {items.map(item => (
          <ApplicationCard key={item.label} app={item} disablePopup={disablePopup} {...cardProps} />
        ))}
      </div>
    </div>
  );
};

export default ApplicationList;

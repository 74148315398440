import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import createAppThunk from '../thunk';
import { get, post } from '../../authentication/axiosInterceptor';

export interface Application {
  label: string;
  url: string;
  imagePath: string;
  description: string;
}

export interface ApplicationsResponse {
  recents: Application[];
  allApps: Application[];
}

export interface ApplicationState {
  query: string;
  recentMaxCount: number;
  apps: Application[];
  recents: Application[];
  filteredRecents: Application[];
  filteredApps: Application[];
}

export const initialState: ApplicationState = {
  query: '',
  recentMaxCount: 5,
  apps: [],
  recents: [],
  filteredRecents: [],
  filteredApps: [],
};

export const applicationSlice = createSlice({
  name: 'applications',
  initialState: initialState,
  reducers: {
    loadAllApplcations(state, action: PayloadAction<ApplicationsResponse>) {
      const { allApps, recents } = action.payload;
      state.apps = allApps;
      state.filteredApps = allApps;
      state.recents = recents;
      state.filteredRecents = recents;
    },
    updateSearchQuery(state, action: PayloadAction<string>) {
      state.query = action.payload.toLowerCase();
    },
    filterApplications(state) {
      state.filteredApps = state.apps.filter(app => app.label.toLowerCase().includes(state.query));
      state.filteredRecents = state.recents.filter(app => app.label.toLowerCase().includes(state.query));
    },
    handleRecents(state, action: PayloadAction<Application>) {
      const index = state.recents.findIndex(app => app.label === action.payload.label);
      if (index >= 0) {
        const [app] = state.recents.splice(index, 1);
        state.recents.unshift(app);
      }
      if (index < 0) state.recents.unshift(action.payload);
    },
  },
});

const fetchApplications = createAppThunk('applications/fetchApplications', async (_request: void, { dispatch }) => {
  try {
    const response = await get<ApplicationsResponse>('/getApps.json');
    if (response.data) dispatch(applicationSlice.actions.loadAllApplcations(response.data));
  } catch (error) {
    console.log('error', error); //NOSONAR
  }
});

const postRecents = createAppThunk('applications/postRecents', async (request: void, { getState }) => {
  try {
    const recents = getState().apps.recents;
    await post('dummy', recents);
  } catch (error) {
    console.log(error); //NOSONAR
  }
});

export const applicationsReducer = applicationSlice.reducer;
export const applicationsActions = { ...applicationSlice.actions, fetchApplications, postRecents };
export default applicationsReducer;
